import React from "react"
import Img from "gatsby-image"
import * as largeImageStyles from "./large_image.module.css"

export default function LargeImage(props) {
  return <div className={largeImageStyles.imagecontainer}>
    <a href={props.link ? props.link : props.image.src}>
      <Img fluid={props.image} />
    </a>
    <span className={largeImageStyles.caption}>{props.caption}</span>
  </div>
}
