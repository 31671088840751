import React from "react"
import * as navigationMobileStyles from "./navigation-mobile.module.css"
import { Link } from "gatsby"

const ListLink = props => (
  <li>
    <Link onClick={props.onClick} to={props.to}>{props.children}</Link>
  </li>
)

const toggleMenu = (e) => {
  document.body.classList.toggle("menu-open", e.target.checked);
}

const closeMenu = (e) => {
  document.getElementById('switch').checked = false;
  document.body.classList.toggle("menu-open", false);
}


export default function Navigation({ children }) {
  return <nav className={navigationMobileStyles.navigation}>
    <div className={navigationMobileStyles.menuToggle}>
      <input id="switch" type="checkbox" onClick={toggleMenu} />
      <span></span>
      <span></span>
      <span></span>

      <Link className={navigationMobileStyles.title} to="/">
        <h3>RÖFES Plattesammlig</h3>
      </Link>
      <ul className={navigationMobileStyles.menu}>
        <ListLink to="#roefe-in-amerika">Röfe in Amerika</ListLink>
        <ListLink onClick={closeMenu} to="#das-erwartet-di">das erwartet di</ListLink>
        <ListLink onClick={closeMenu} to="#dr-roefe">dr Röfe</ListLink>
        <ListLink onClick={closeMenu} to="#musig">d Musig</ListLink>
        <ListLink onClick={closeMenu} to="#so-toents">so tönts</ListLink>
        <ListLink onClick={closeMenu} to="#kontakt">di Kontakt</ListLink>
        <ListLink onClick={closeMenu} to="#press">d Press</ListLink>
        <ListLink onClick={closeMenu} to="#uftritte">d Uftritte</ListLink>
      </ul>
    </div>

    {children}
  </nav>
}
